<template>
  <div class="user">
    <div class="tit">{{ $t('reg_findPassword_1') }}</div>
    <div class="user-box">
      <div class="limit-tit">{{ $t('reg_findPassword_2') }}：</div>
      <div class="limit-ls">1.{{ $t('reg_findPassword_3') }}（A-Z）</div>
      <div class="limit-ls">2.{{ $t('reg_findPassword_4') }}（a-z）</div>
      <div class="limit-ls">3.{{ $t('reg_findPassword_5') }}（0-9）</div>
      <div class="limit-ls">
        4.{{ $t('reg_findPassword_6') }} (~!@#$%^&*()_+`\-={}:";'&lt;>,.)
      </div>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('reg_findPassword_7')" prop="newpassword">
          <el-input
            type="password"
            v-model="ruleForm.newpassword"
            :placeholder="$t('reg_findPassword_8')"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reg_findPassword_9')"
          prop="confirm_newpassword"
        >
          <el-input
            type="password"
            v-model="ruleForm.confirm_newpassword"
            :placeholder="$t('reg_findPassword_10')"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="textCenter">
      <el-button :loading="nextLoading" @click="next" type="primary">
        {{ $t('reg_findPassword_11') }}
      </el-button>
      <!-- <el-button @click="back" type="text">{{ $t('返回') }}</el-button>
      <div class="tip" @click="toLogin">{{ $t('已有账号，立即登录') }}</div> -->
    </div>
  </div>
</template>

<script>
import { getUrlArgs } from '@/utils/tool.js'
export default {
  name: 'Email',
  data() {
    let that = this
    let validatePass = (rule, value, callback) => {
      let reg =
        /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[~!@#$%^&*()_+`\-={}:";'<>,.])(?=.*[~!@#$%^&*()_+`\-={}:";'<>,.]))[a-z\d~!@#$%^&*()_+`\-={}:";'<>,.]{8,}/i
      if (value === '') {
        callback(new Error(that.$t('请输入密码')))
      } else if (!reg.test(value)) {
        callback(new Error(that.$t('密码格式不正确')))
      } else {
        if (this.ruleForm.confirm_newpassword !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(that.$t('请再次输入密码')))
      } else if (value !== this.ruleForm.newpassword) {
        callback(new Error(that.$t('两次输入密码不一致！')))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        type: 'email',
        mobile: '',
        email: getUrlArgs('email'),
        captcha: getUrlArgs('code'),
        newpassword: '',
        confirm_newpassword: '',
      },
      rules: {
        newpassword: [{ validator: validatePass, trigger: 'blur' }],
        confirm_newpassword: [{ validator: validatePass2, trigger: 'blur' }],
      },
      nextLoading: false,
    }
  },
  mounted() {},
  methods: {
    async next() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.nextLoading = true
          let res = await this.$axios.post('/api/User/resetpwd', this.ruleForm)
          this.nextLoading = false
          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.$router.push({ name: 'Login' })
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  width: 600px;
  margin: 80px auto 0;
  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 3px;
    margin-bottom: 40px;
    text-align: center;
  }
}
.limit-tit {
  font-size: 18px;
  color: #333333;
  margin-bottom: 10px;
}
.limit-ls {
  margin-bottom: 10px;
}
.demo-ruleForm {
  margin-top: 30px;
  width: 400px;
}
.textCenter {
  margin-top: 50px;
  .el-button {
    width: 100%;
    margin-left: 0;
  }
  .tip {
    color: #409eff;
    margin-top: 20px;
    cursor: pointer;
  }
}
</style>
